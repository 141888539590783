export const largeViewportMediaQueryList = window.matchMedia(`(min-width: 64rem)`);

// .Fa rotate P3B
$('.trigger-rotate').click(function () {
	$(this).find('.fa').toggleClass('rotate180');
	$(this).find('i').toggleClass('rotate180');
});

// View Rates P3B

$(".view-rates-btn").click(function () {
	$(".icon-chevron-down").toggleClass("icon-chevron-up");
});

// Init tooltip
$(function () {
	$('[data-toggle="tooltip"]').tooltip()
})

// Lazy Load
export let lazyLoadInstance;
document.addEventListener('DOMContentLoaded', function () {
	if (typeof LazyLoad != 'function') {
		return;
	}
	lazyLoadInstance = new LazyLoad({
		elements_selector: '.lazyload'
	});
});

// Spinner
document.addEventListener("DOMContentLoaded", () => {
	$('.loading-spinner').on('click', handleAddingSpinner);
});

export function handleAddingSpinner(event) {
	if(event.ctrlKey) {
		return;
	}
	const buttonDomEl = event.currentTarget;
	const validBool = buttonDomEl.form !== undefined ? buttonDomEl.form.checkValidity() : true;
	if (buttonDomEl.dataset.hasSpinner === undefined && validBool) {
		buttonDomEl.dataset.hasSpinner = "";
		buttonDomEl.appendChild(document.createElement("i")).classList.add("fa", "fa-spinner", "fa-spin", "ms-2");
	}
}

window.addEventListener("pageshow", () => {
	document.querySelectorAll(".loading-spinner[data-has-spinner]").forEach(el => {
		el.querySelectorAll(".fa-spinner").forEach(spinner => el.removeChild(spinner));
		delete el.dataset.hasSpinner;
	});
});

// Sliders
export const slickConfigObj = {
    lazyLoad: 'ondemand',
    arrows: true,
    autoplay: false,
    dots: true
};
const observerSlick = new IntersectionObserver(handleInitSlick, {root: null, rootMargin: "0px", threshold: 0});
document.querySelectorAll(".standard-slider, .room-modal-slider").forEach(el => observerSlick.observe(el));

function handleInitSlick(entries, observer) {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            if(entry.target.classList.contains("room-modal-slider")){
                $(entry.target).slick({
                    arrows: true,
                    autoplay: true
                });
            } else {
                $(entry.target).slick(slickConfigObj);
            }
            observer.unobserve(entry.target);
        }
    }
}

$('.modal').on('shown.bs.modal', function (e) {
    $(this).find('.standard-slider:not([data-slick-defer-load]), .slider-nav, .gallery-slider, .room-modal-slider').slick('setPosition');
})

window.addEventListener("DOMContentLoaded", () => {
	"use strict";
	document.querySelectorAll("[data-tablist-arrows]").forEach(el => {
		el.addEventListener("keydown", handleKeyDownOnTabList);
	});
});

/**
 *
 * @param {KeyboardEvent} event
 */
export function handleKeyDownOnTabList(event) {
	"use strict";
	switch (event.key) {
		case "ArrowLeft":
		case "ArrowUp":
			event.preventDefault();
			focusNextTab(Array.from(event.currentTarget.querySelectorAll("[role='tab']:not([disabled])")).reverse(), event.target);
			break;
		case "ArrowRight":
		case "ArrowDown":
			event.preventDefault();
			focusNextTab(Array.from(event.currentTarget.querySelectorAll("[role='tab']:not([disabled])")), event.target);
			break;
	}
}

function focusNextTab(tabsArr, currentTabEl) {
	"use strict";
	const currentIndexInt = tabsArr.findIndex(el => el === currentTabEl);
	let newIndex = currentIndexInt + 1;
	if (newIndex >= tabsArr.length) {
		newIndex = 0;
	}
	const tabEl = tabsArr[newIndex];
	tabEl.click();
	tabEl.focus();
}
